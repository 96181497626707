import React from 'react'
import "./Gallery.css";
function Gallery() {
  return (
    <div>
        

{/* Gallery */}
<h3 className='text-center mt-5'>Gallery</h3>
<div className="gallery-container">
  <div className="c">
    <img 
      src="https://img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Image(1)-min.jpeg" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 1" 
    />
  </div>
  <div className="c">
    <img 
      src="https://img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Image-min.jpeg" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 2" 
    />
  </div>
  <div className="c">
    <img 
      src="https://www.shutterstock.com/shutterstock/videos/3549918581/thumb/1.jpg?ip=x480" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 3" 
    />
  </div>
  <div className="c">
    <img 
      src="https://images.squarespace-cdn.com/content/v1/5af3a1255cfd795b7630a981/1590829414320-4EZZOCVGA2P8F0ZQAFDK/P1120802.jpg" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 4" 
    />
  </div>
  <div className="c">
    <img 
      src="https://precisionautomationinc.com/wp-content/uploads/2021/03/IMG_2460-edited-scaled.jpg" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 5" 
    />
  </div>
  <div className="c">
    <img 
      src="https://img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Milling%201-min.jpg" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 6" 
    />
  </div>
  <div className="c">
    <img 
      src="https://img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Milling%201-min.jpg" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 6" 
    />
  </div>
  <div className="c">
    <img 
      src="https://img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Milling%201-min.jpg" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 6" 
    />
  </div>
  <div className="c">
    <img 
      src="https://img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Milling%201-min.jpg" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 6" 
    />
  </div>
  <div className="c">
    <img 
      src="https://img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Milling%201-min.jpg" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 6" 
    />
  </div>
  <div className="c">
    <img 
      src="https://img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Milling%201-min.jpg" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 6" 
    />
  </div>
  <div className="c">
    <img 
      src="https://img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Milling%201-min.jpg" 
      className="img-fluid custom-img-size" 
      alt="Milling Image 6" 
    />
  </div>

</div>


    </div>
  )
}

export default Gallery