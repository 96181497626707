import { useLocation } from 'react-router-dom';
import './View.css'

const View = () => {


  const location = useLocation();
  const { name, image, description } = location.state || {};
  console.log("name, image",name, image);




  

  return (
    <div>
      {/* <div>
        <img className='vie' src={image} alt="PRECISION"/>
      </div>

      <div className="container mt-5 text-center">
        <div className="px-4 px-lg-5 mt-7 border-white rounded fw-bolder ">
          <div className="about gx-4 gx-lg-5">
            <p style={{ fontSize: '320%' }} className='text-primary'>{name}</p>
            <p>{description}</p>
          </div>
        </div>
      </div>
 */}




<div className="container mt-5 text-center">
  <div className="row justify-content-center">
    <div className="col-12 col-md-8 col-lg-6">
      <img className="img-fluid vie" 
      src={image} alt="PRECISION" 
      />
    </div>
  </div>

  <div className="mt-5 text-center">
    <div className="px-4 px-lg-5 mt-4 border-white rounded fw-bolder">
      <div className="about gx-4 gx-lg-5">
        <p
          className="text-primary"
          style={{ fontSize: 'calc(1.6rem + 1vw)' }}
        >
          {name}
        </p>
        <p className="lead">{description}</p>
      </div>
    </div>
  </div>
</div>











      
    </div>
  )
}

export default View;
