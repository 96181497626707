import React from 'react'
import "./About.css";

function About() {



    



  return (
    <>




{/* about */}


    <div class="container  mt-5 text-center">
            <div class="about gx-4 gx-lg-5    ">
    
                <p style={{fontSize:'320%'}} className='text-warning'> PRECISION </p>
            </div>
    </div>




<body>
  <div className='about-wrapper'>
  <div class=" row">
    <div class=" col-md-6">
      <img src="https://img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Milling%201-min.jpg" class="img-fluid custom-img-size" alt="Precision Engineering Image" />
    </div>
    <div class="cardi col-md-6 ">
      <div class="Precision-title">
        <h1 className='mb-4'>Precision Engineering</h1>
        <h2>PRECISION Engineering Services is a UAE based ISO 9001:2015 certified company providing a wide range of high precision machining and fabrication services to the Oil and Gas, Defense, Automobile, Aviation, and other major engineering industries. The company is located in Abu Dhabi, UAE and provides efficient services to customers in the Middle East region.</h2>
      </div>
    </div>
  </div>
  </div>
</body>







    </>
  )
}

export default About