import React from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const goToViewPage = () => {
    navigate('/view', {
       state: {
         name: "PRECISION",
         image: "//img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Product%201-min.jpg",
         description: "PRECISION Engineering Services is a UAE-based ISO 9001:2015 certified company providing a wide range of high-precision machining and fabrication services to the Oil and Gas, Defense, Automobile, Aviation, and other major engineering industries. The company is located in Abu Dhabi, United Arab Emirates, and provides efficient and responsive services to customers in the UAE and the entire Middle East region."
  
       }
    });
  };


  const goToWorkPage = () => {
    navigate('/view', {
      state: {
        name: "THREADING WORKS AND PROFILING",
        image: "//img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Lathe-min.jpg",
        description: " PRECISION can undertake different types of thread works including API, Premium, ACME, Stub acme etc and other customized threads for various engineering requirements. Our sophisticated machines can provide OD/ ID turning, boring and   profile machining on the free issued materials within tolerance limits as per the customer drawings."
  
      }
    });
  };


  const goToequipmentsPage = () => {
    navigate('/view', {
      state: {
        name: "MECHANICAL EQUIPMENT AND CONSUMABLES SUPPLY",
        image: "//img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/design-min.jpg",
        description: " PRECISION supplies various mechanical equipment & consumables catering to all industries."
  
      }
    });
  };


  const goTomanufacturingPage = () => {
    navigate('/view', {
      state: {
        name: "TURNKEY MANUFACTURING",
        image: "//img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Products-min.jpg",
        description: " PRECISION manufactures products like Crossovers, Subs, Flow Couplings, Blast joints, Circulating Heads, Adaptors etc from different grades of raw materials complying to international standards like AISI 4130, 4140, 4145, 4330 etc.."
  
      }
    });
  };


  const goTofabricationPage = () => {
    navigate('/view', {
      state: {
        name: "WELDING AND FABRICATION",
        image: "https://www.fvtc.edu/Portals/0/POSImages/Programs/metal-fabrication-welding-lg.jpg",
        description: " PRECISION supplies different types of Cargo Baskets, Cylinder racks, Long baskets, Lifting Frames, Tool Stand, Mooring Buoys, Waste Skip, Gantry Crane, Rescue Baskets, Material Baskets, Material Racks, Lifting Beam, Metal Box, Metal Trolley, Inspection Rack & Lifting equipment."
  
      }
    });
  };





  return (
    <div className="black">
      <div > 
        {/* <div className="intro"> */}
          {/* navbar */}
         
        {/* </div> */}


          <div className="intro"></div>





        {/* about */}

        <div class="container  mt-5 text-center">
          <div class="px-4 px-lg-5 mt-7  border-white rounded fw-bolder ">
            <div class="about gx-4 gx-lg-5    ">
              <p style={{ fontSize: "310%" }} className="text-primary">
                {" "}
                PRECISION{" "}
              </p>
              <a  style={{ fontSize: "150%" }} >
                PRECISION Engineering Services is a UAE based ISO 9001:2015
                certified Company providing a wide range of high precision
                Machining and Fabrication services to the Oil and Gas, Defense,
                Automobile, Aviation, and other major engineering Industries.
                The company is located in Abu Dhabi, United Arab Emirates and
                providing efficient and responsive services to customers in UAE
                and the entire Middle East region.
              </a>
            </div>
          </div>
        </div>

        <div>
          <p
            style={{ fontSize: "290%" }}
            className="text-center text-primary mt-5 p-5"
          >
            Services
          </p>

  <div className="service-image-wrapper">
  <img
    className="service-image"
    src="//img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/quality-min.jpg/:/rs=w:1240,h:932.3308270676691,cg:true,m/cr=w:1240,h:932.3308270676691"
    alt="service" 
  />
</div>
        </div>

        {/* bullet links */}
{/* 
        <div className="bulle">
          <ul class="list-groupitem">
            <li class="list-item list-item-primary d-flex justify-content-between align-items-center ">
              <a onClick={goToViewPage} className="link">
                • CNC Machining
              </a>
            </li>
            <li class="list-item list-item-primary d-flex justify-content-between align-items-center">
              <a onClick={goTomanufacturingPage} className="link" >
                • Turnkey Manufacturing
              </a>
            </li>
            <li class="list-item list-item-primary d-flex justify-content-between align-items-center">
              <a onClick={goToequipmentsPage} className="link" >
                • Mechanical Equipment and consumables supply
              </a>
            </li>

            <li class="list-item list-item-primary d-flex justify-content-between align-items-center">
              <a onClick={goToWorkPage} className="link" >
                • Threading Works and profiling
              </a>
            </li>

            <li class="list-item list-item-primary d-flex justify-content-between align-items-center">
              <a onClick={goTofabricationPage} className="link" >
                • Welding and Fabrication
              </a>
            </li>
          </ul>
        </div> */}




<div className="bulle">
  <ul >
    <li className=" d-flex justify-content-between align-items-center">
      <a onClick={goToViewPage} className="link">
        • CNC Machining
      </a>
    </li>
    <li className=" d-flex justify-content-between align-items-center">
      <a onClick={goTomanufacturingPage}  className="link">
        • Turnkey Manufacturing
      </a>
    </li>
    <li className=" d-flex justify-content-between align-items-center">
      <a onClick={goToequipmentsPage} className="link">
        • Mechanical Equipment and Consumables Supply
      </a>
    </li>
    <li className=" d-flex justify-content-between align-items-center">
      <a onClick={goToWorkPage} className="link">
        • Threading Works and Profiling
      </a>
    </li>
    <li className="d-flex justify-content-between align-items-center">
      <a onClick={goTofabricationPage} className="link">
        • Welding and Fabrication
      </a>
    </li>
  </ul>
</div>


      </div>












<div className="row ">
  <div className="col-md-6 ">
  <div className="text-center">
    <h1 className='text-center mt-5'>Contact Us</h1>
    <h2>Precision Engineering Services</h2>
    <p>We love our customers, so feel free to visit during normal business hours.</p>
    
    <h4>Location:</h4>
    <p>Precision Engineering Services - Mussafah 15 - Abudhabi,U.A.E</p>

    <h4>Contact Us:</h4>
    <p>Email: <a href="mailto:sales@precimetal.ae">info@precision-uae.com</a></p>
    <p>Email: <a href="mailto:operations@precimetal.ae">operations@precision.ae</a></p>
    <p>Phone: <a href="Mob:0542961601">0542961601</a></p>


    <h4>Office Hours:</h4>
    <p>Open today: 08:00 AM – 05:00 PM</p>
  </div>
  </div>

<div className="col-md-6">

  <div className=" mt-5">
  
    <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14539.789378035839!2d54.47567180000001!3d24.3483268!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e419b18d9de6d%3A0x89ecfdfcf586ce1d!2sPrecimetal%20Engineering%20Services!5e0!3m2!1sen!2sin!4v1729333091188!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
</div>
</div>

    </div>
  );
}

export default Home;
