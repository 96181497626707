import React from 'react'
import "./Certificate.css";

function Certificate() {
  return (

<div>
    
    
<div className="container mt-5 text-center">
  <div className="px-3 px-lg-5 mt-5 border-white rounded fw-bolder">
    <div className="row justify-content-center">
      <div className="col-12 col-lg-8">
        <p style={{ fontSize: '2.2em' }} className="text-primary">OUR CERTIFICATIONS</p>
        <p>Our Quality Systems are in accordance with the latest ISO 9001 standards.</p>
      </div>
    </div>
  </div>
</div>

<div className="text-center my-4">
  <img className="img-fluid certificate custom-img" src="preci.jpg" alt="Certification" />
</div>      
    
</div>  
  )
}

export default Certificate