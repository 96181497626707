import React from 'react'
import "./Contact.css";

function Contact() {
  return (
    <div>
        
 
      <h1 className='text-center mt-5'>Contact Us</h1>

<div class=" text-center">
    <h2>Precision Engineering Services</h2>
    <p>We love our customers, so feel free to visit during normal business hours.</p>
    
    <h4>Location:</h4>
    <p>Precision Engineering Services - Mussafah 15 - Abudhabi,U.A.E</p>

    <h4>Contact Us:</h4>
    <p>Email: <a href="mailto:sales@precimetal.ae">info@precision-uae.com</a></p>
    <p>Email: <a href="mailto:operations@precimetal.ae">operations@precision.ae</a></p>
    <p>Phone: <a href="Mob:0542961601">0542961601</a></p>

    
</div>



{/* location */}

<div className='map-wrapper '>
    {/* <!-- Google Maps Embed --> */}
{/* <div className='col-md-6'> */}
      
<iframe className='title' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14539.789378035839!2d54.47567180000001!3d24.3483268!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e419b18d9de6d%3A0x89ecfdfcf586ce1d!2sPrecimetal%20Engineering%20Services!5e0!3m2!1sen!2sin!4v1729333091188!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    {/* </div> */}
  
  
</div>
    </div>
  )
}

export default Contact