import './App.css';
import { Routes,Route } from 'react-router-dom';
import Home from './Home/Home';
import View from './View/View';
import Footer from './Footer/Footer';
import Works from './Works/Works';
import About from './About/About'
import Certificate from './Certificate/Certificate'
import Contact from './Contactus/Contact';
import Employees from './Employees/Employees';
import Gallery from './Gallery/Gallery';
import Header from './Header/Header';
// import ChatForm from './Chat/ChatForm';


function App() {
  return (
    <>
<Header/>

<Routes>
<Route path='/' element={<Home/>}/>
<Route path='/View' element={<View/>}/>
<Route path='/works' element={<Works/>}/>
<Route path='/about' element={<About/>}/>
<Route path='/certificate' element={<Certificate/>}/>
<Route path='/contact' element={<Contact/>}/>
<Route path='/employees' element={<Employees/>}/>
<Route path='/gallery' element={<Gallery/>}/>
{/* <Route path='/ChatForm' element={<ChatForm/>}/> */}

</Routes>


<Footer/>

    </>
  );
}

export default App;
