import React,{useState}  from 'react'
import './Header.css'
import { useNavigate } from "react-router-dom";



function Header() {


  // navbar toogler
    const [isNavbarOpen, setisNavbarOpen] = useState(false);

    // chat opening form function

    const [isOpen, setIsOpen] = useState(false);

    const toggleChat = () => {
        setIsOpen(!isOpen);
      };
  
      const toggleNavbar = () => {
        setisNavbarOpen(!isNavbarOpen);
      };


// same size file navigation navbar
  
    
    const navigate = useNavigate();

    const goToViewPage = () => {
      navigate('/view', {
        state: {
          name: "PRECISION",
          image: "//img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Product%201-min.jpg",
          description: "PRECIMETAL Engineering Services is a UAE-based ISO 9001:2015 certified company providing a wide range of high-precision machining and fabrication services to the Oil and Gas, Defense, Automobile, Aviation, and other major engineering industries. The company is located in Abu Dhabi, United Arab Emirates, and provides efficient and responsive services to customers in the UAE and the entire Middle East region."
    
        }
      });
    };
  
  
    const goToWorkPage = () => {
      navigate('/view', {
        state: {
          name: "THREADING WORKS AND PROFILING",
          image: "//img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Lathe-min.jpg",
          description: " PRECISION can undertake different types of thread works including API, Premium, ACME, Stub acme etc and other customized threads for various engineering requirements. Our sophisticated machines can provide OD/ ID turning, boring and   profile machining on the free issued materials within tolerance limits as per the customer drawings."
    
        }
      });
    };
  
  
    const goToequipmentsPage = () => {
      navigate('/view', {
        state: {
          name: "MECHANICAL EQUIPMENT AND CONSUMABLES SUPPLY",
          image: "//img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/design-min.jpg",
          description: " PRECISION supplies various mechanical equipment & consumables catering to all industries."
    
        }
      });
    };
  
  
    const goTomanufacturingPage = () => {
      navigate('/view', {
        state: {
          name: "TURNKEY MANUFACTURING",
          image: "//img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Products-min.jpg",
          description: " PRECISION manufactures products like Crossovers, Subs, Flow Couplings, Blast joints, Circulating Heads, Adaptors etc from different grades of raw materials complying to international standards like AISI 4130, 4140, 4145, 4330 etc.."
    
        }
      });
    };
  
  
    const goTofabricationPage = () => {
      navigate('/view', {
        state: {
          name: "WELDING AND FABRICATION",
          image: "https://www.fvtc.edu/Portals/0/POSImages/Programs/metal-fabrication-welding-lg.jpg",
          description: " PRECISION  supplies different types of Cargo Baskets, Cylinder racks, Long baskets, Lifting Frames, Tool Stand, Mooring Buoys, Waste Skip, Gantry Crane, Rescue Baskets, Material Baskets, Material Racks, Lifting Beam, Metal Box, Metal Trolley, Inspection Rack & Lifting equipment."
    
        }
      });
    };

    // navigation navbar
  
    const aboutall = ()=>{
        navigate('/about')
    }
    const certificates = ()=>{
        navigate('/certificate')
    }
    const listall = ()=>{
        navigate('/employees')
    }
    const contactus = ()=>{
        navigate('/contact')
    }
    const images = ()=>{
        navigate('/gallery')
    }
    


  return (
    <div>

{/* navbar */}

<nav className="navbar navbar-expand-lg  p-0 " >
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            style={{ width: '170px' }}
            className="mt-2 m-1"
            src="logo.png"
            alt="image"
          />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarColor02"
          aria-expanded={isNavbarOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
          
        </button>

        <div className={`col collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} id="navbarColor02">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="/">
                HOME
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                ABOUT US
              </a>
              <div className="dropdown-menu">
                <a onClick={aboutall} className="dropdown-item">
                  Precision
                </a>
                <a onClick={certificates} className="dropdown-item">
                  Certification
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                PRODUCT & SERVICES
              </a>
              <div className="dropdown-menu">
                <a onClick={goToViewPage} className="dropdown-item">
                  CNC Machining
                </a>
                <a onClick={goTomanufacturingPage} className="dropdown-item">
                  Turnkey Manufacturing
                </a>
                <a onClick={goToequipmentsPage} className="dropdown-item">
                  Mechanical Equipments
                </a>
                <a onClick={goToWorkPage} className="dropdown-item">
                  Threading Works
                </a>
                <a onClick={goTofabricationPage} className="dropdown-item">
                  Welding and Fabrication
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                MORE
              </a>
              <div className="dropdown-menu">
                <a onClick={listall} className="dropdown-item">
                  List Of Equipments
                </a>
                <a onClick={contactus} className="dropdown-item">
                  Contact Us
                </a>
                <a onClick={images} className="dropdown-item">
                  Gallery
                </a>
              </div>
            </li>
          </ul>
        </div>
        {isNavbarOpen && (
          <button
            className="btn-close position-absolute top-0 end-0 m-2"
            onClick={toggleNavbar}
            aria-label="Close"
          ></button>
        )}
      </div>
    </nav>




{/* chat button */}

  <div className="App">
    <div className={`chat-button ${isOpen ? "open" : ""}`} onClick={toggleChat}>
      {isOpen ? <span className="close-icon">✖</span> : <span className="chat-icon">💬</span>}
    </div>

    <div className={`chat-form ${isOpen ? "dropup" : "hide"}`}>
      <div className="chat-header">PRECISION</div>
      <form className="chat-content">
        <label htmlFor="name">Name</label>
        <input type="text" id="name" placeholder="Enter your name" />
        <label htmlFor="email">Email</label>
        <input type="email" id="email" placeholder="Enter your email" />
        <label htmlFor="description">Description</label>
        <textarea id="description" placeholder="Enter a description"></textarea>
        <button type="submit" className="send-button">Send</button>
      </form>
      <p className="privacy-note">This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
    </div>
  </div>



    </div>
  )
}

export default Header