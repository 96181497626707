import React from 'react'
import './Footer.css';


function Footer() {
  return (




<div>
      
      <div>
  
  <div className=' foot d-flex justify-content-center py-4 mt-5 '>
  &copy; All Right Are Reserved &reg;  
  PRECISION 
  </div>
  
  
  
      </div>
  
  
  
</div>




  )
}

export default Footer