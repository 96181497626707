import React from 'react'
import "./Employees.css";

function Employees() {
  return (
    <div>


  

{/* about */}


<div className="container  mt-5 text-center">
            <div className="about gx-4 gx-lg-5    ">
    
                <p style={{fontSize:'250%'}} className='text-warning'> PRECISION  </p>
            </div>
    </div>




<body>
  <div className='employees-wrapper' >
  <div className=" row">
    <div className="card-one-image col-md-6">
      <img src="//img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/cnc%20lathe-min.jpeg" className="img-fluid custom-img-size" alt="Precision Engineering Image" />
    </div>
    <div className="col-md-6 ">
      <div className="title card-one-title">
      <h1>CNC Lathe</h1>
 <h2>Make   : Doosan </h2>
 <h2>Model   : PUMA 400 L </h2>
 <h2>Max.   swing diameter : Ø 700 mm</h2>
 <h2>Max.   turning diameter : Ø 550 mm</h2>
 <h2>Max.   turning length : 2000 mm</h2>
 <h2>Spindle   speed : 2000 rpm</h2>
 <h2>Power   Capacity : 52 KW, 3 Ø</h2>
      </div>
    </div>
  </div>
  <section className='mt-5'>
  <div className=" row">
    <div className=" col-md-6 ">
      <div className="title">
        <h1>CNC Lathe</h1>
        <h2> Make   : YOU JI MACHINE INDUSTRIAL CO. LTD, TAIWAN</h2>
        <h2>Model   : YH-36/200, 2014</h2>
       <h2>Max.   swing diameter : Ø 720 mm</h2>
      <h2>Max.   turning diameter : Ø 600 mm</h2>
       <h2>Max.   turning length : 2000 mm</h2>
       <h2>Spindle   speed : 2000 rpm  </h2>
       <h2>Power   Capacity : 65 KW, 3 Ø</h2>
      </div>
    </div>
    <div className=" col-md-6">
      <img src="https://www.thompsonprecision.co.uk/wp-content/uploads/2023/04/precision-engineered-components.webp" className="img-fluid custom-img-size" alt="Precision Engineering Image" />
    </div>

  </div>
</section>
<div className=" row mt-5">
    <div className=" col-md-6">
      <img src="https://mad-cnc.co.uk/wp-content/uploads/2021/09/PIC_Action-CTCM120-130_-scaled.jpg" className="img-fluid custom-img-size" alt="Precision Engineering Image" />
    </div>
    <div className="col-md-6 ">
      <div className="title">
      <h1>CNC Milling</h1>
        <h2>Make   : SMITH LEHMANN, USA</h2>
        <h2>Max.   swing diameter : Ø 1200 mm</h2>
       <h2>Max.   Turning diameter : Ø 800 mm</h2>
      <h2>Max.   Turning length : 3000 mm</h2>
       <h2>Hollow   Spindle Bore : 12"</h2>
       <h2>Power   Capacity : 50 KW</h2>


      </div>
    </div>
  </div>
  <div className=" row mt-5">
    <div className=" col-md-6 ">
      <div className="title">
        <h1>Manual Lathe</h1>
        <h2>Make   : YOU JI MACHINE INDUSTRIAL CO. LTD, TAIWAN</h2>
        <h2>Model   : VMC-1350, 2014</h2>
       <h2>X-axis   travel : 1350 mm</h2>
      <h2>Y-axis   travel : 700 mm</h2>
       <h2>Z-axis   travel : 700 mm</h2>
       <h2>Spindle   speed : 8000 rpm</h2>
       <h2>Max.   Speed of main Spindle : 4500 RPM</h2>
       <h2>Power   Capacity : 25 KW, 3 Ø</h2>
      </div>
    </div>
    <div className="col-md-6">
      <img src="//img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/milling-min.jpeg" className="img-fluid custom-img-size" alt="Precision Engineering Image" />
    </div>

  </div>
</div>
</body>

    </div>
  )
}

export default Employees